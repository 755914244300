import React, { Fragment, useState, useEffect } from "react";
import "./index.css";
import { logo, collapsedLogo } from "../../components/Images/Images";
import { ApiCall } from "../../utils/api";
import VistaApp from "../Vista/src/VistaApp";
import LoginBox from "./LoginBox";
import { useHistory } from "react-router";
import { getCookie } from "../../utils/commonFunctions";
import CommonLoader from "../../components/Widgets/CommonLoader";

const Login = (props) => {
  const [isRenderLogin, setIsRenderLogin] = useState(false);
  const [flag, setFlag] = useState(false);
  const [bgColor, setBgColor] = useState("");
  const [loginLogo, setLoginLogo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "admin-grv8.dev.brainvire.net" ||
      window.location.hostname === "admin.staging.gravit8.io" ||
      window.location.hostname === "grv8-admin.dev.brainvire.net"
    ) {
      if (navigator.onLine)
        window.less.modifyVars({ "@primary-color": "#ef0855" }).then(() => {});
      if (getCookie("Authorization")) {
        history.goForward(); //Go to the next page in the stack
      } else {
        localStorage.clear();
      }

      localStorage.setItem("event_color_theme", "#ef0855");
      localStorage.setItem("hostname", window.location.hostname);
      localStorage.setItem("event_logo_url", logo);
      setLoginLogo(logo);

      setFlag(false);
      setIsLoading(false);
      setIsRenderLogin(true);
    } else
      ApiCall("get", null, "/admin/subdomain/get", null, true)
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.message === "Successful Operation"
          ) {
            setIsRenderLogin(true);

            if (response.data && response.data.data) {
              if (getCookie("Authorization")) {
                history.goForward(); //Go to the next page in the stack
              } else {
                localStorage.clear();
              }

              if (
                response?.data?.data?.exterior_url &&
                response?.data?.data?.is_exterior_active
              ) {
                localStorage.setItem(
                  "exterior_url",
                  response?.data?.data?.exterior_url
                );
                setFlag(true);
              } else {
                setFlag(false); //If exterior file is not uploaded show default gravit8 background
              }

              if (!response?.data?.data?.is_exterior_active) {
                if (response.data.data.login_background_theme)
                  setBgColor(response.data.data.login_background_theme);
              }

              let default_button_background_color =
                response.data.data.default_button_background_color ?? "#ffffff";
              let default_button_background_second_color = response.data.data
                .default_button_background_second_color
                ? response.data.data.default_button_background_second_color
                : response.data.data.default_button_background_color
                ? response.data.data.default_button_background_color
                : "#ffffff";

              let default_button_gradient_angle =
                response.data.data.default_button_gradient_angle ?? 0;
              let default_button_text_color =
                response.data.data.default_button_text_color ?? "#ef0855";
              let highlight_button_background_color =
                response.data.data.highlight_button_background_color ??
                "#ef0855";
              let highlight_button_background_second_color = response.data.data
                .highlight_button_background_second_color
                ? response.data.data.highlight_button_background_second_color
                : response.data.data.highlight_button_background_color
                ? response.data.data.highlight_button_background_color
                : "#ef0855";
              let highlight_button_gradient_angle =
                response.data.data.highlight_button_gradient_angle ?? 0;
              let highlight_button_text_color =
                response.data.data.highlight_button_text_color ?? "#ffffff";
              let event_color_theme =
                response.data.data.event_color_theme ?? "#ef0855";

              if (!localStorage.getItem("event_color_theme")) {
                window.less
                  .modifyVars({
                    "@primary-color": event_color_theme,
                    "@default_button_background_color":
                      default_button_background_color,
                    "@default_button_background_second_color":
                      default_button_background_second_color,
                    "@default_button_text_color": default_button_text_color,
                    "@highlight_button_background_color":
                      highlight_button_background_color,
                    "@highlight_button_background_second_color":
                      highlight_button_background_second_color,
                    "@highlight_button_text_color": highlight_button_text_color,
                    "@default_button_gradient_angle":
                      default_button_gradient_angle + "deg",
                    "@highlight_button_gradient_angle":
                      highlight_button_gradient_angle + "deg",
                  })
                  .then(() => {});

                localStorage.setItem("event_color_theme", event_color_theme);
                localStorage.setItem(
                  "btnColorData",
                  JSON.stringify({
                    default_button_background_color,
                    default_button_background_second_color,
                    default_button_gradient_angle:
                      default_button_gradient_angle + "deg",
                    default_button_text_color,
                    highlight_button_background_color,
                    highlight_button_background_second_color,
                    highlight_button_gradient_angle:
                      highlight_button_gradient_angle + "deg",
                    highlight_button_text_color,
                  })
                );
              }

              setLoginLogo(response?.data?.data?.event_logo_url);
              localStorage.setItem(
                "event_logo_url",
                response?.data?.data?.event_logo_url
              );

              localStorage.setItem(
                "minimized_logo_url",
                response.data.data.minimized_logo_url
              );
              localStorage.setItem("event_id", response.data.data.event_id);
            }
          }
        })
        .catch((err) => {
          console.error("err in domain api", err);
        })
        .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      {isLoading ? (
        <CommonLoader />
      ) : (
        <Fragment>
          {flag ? (
            <div
              style={{ width: "100%", height: "100vh", position: "relative" }}
            >
              <VistaApp props={props} />
            </div>
          ) : (
            <div
              style={{ backgroundColor: bgColor }}
              className="pt-5 login-bg-img-div"
            >
              <div className="site-card-border-less-wrapper  pt-5 text-center">
                {loginLogo ? (
                  <img className="login_img_logo" src={loginLogo} alt="logo" />
                ) : null}
                <LoginBox isRenderLogin={isRenderLogin} loginProps={props} />
              </div>
            </div>
          )}
        </Fragment>
      )}
    </>
  );
};
export default Login;
